import Login from "@/views/Login";
import Home from "@/views/Home.vue";
import TmsCopies from "@/views/TmsCopies.vue";
import TmsCopyCredentials from "@/views/TmsCopyCredentials.vue";
import TmsCopyUsers from "@/views/TmsCopyUsers.vue";
import Rbac from "@/views/Rbac.vue";


export default [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {title: 'Логин', layout: 'empty-layout'}
    },
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {title: 'Главная'}
    },
    {
        path: '/tms-copies',
        name: 'TmsCopies',
        component: TmsCopies,
        meta: {title: 'Копии TMS'}
    },
    {
        path: '/tms-copy/:id(\\d+)/credentials',
        name: 'TmsCopyCredentials',
        component: TmsCopyCredentials,
        meta: {title: 'Creds'}
    },
    {
        path: '/tms-copy/:id(\\d+)/users',
        name: 'TmsCopyUsers',
        component: TmsCopyUsers,
        meta: {title: 'Users'}
    },
    {
        path: '/rbac',
        name: 'Rbac',
        component: Rbac,
        meta: {title: 'Rbac'}
    },


]
