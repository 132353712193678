import Vue from 'vue'

import ElementUI from 'element-ui';

import './assets/css/element-variables.scss';

import locale from 'element-ui/lib/locale/lang/ru-RU'
import App from './App.vue'

import vueDebounce from 'vue-debounce'


import ApiPlugin from './plugins/api'
import ConstantsPlugin from './plugins/constants'
import PermissionsPlugin from './plugins/permissions'

import router from './router'

import store from './store'


Vue.config.productionTip = false

Vue.use(ApiPlugin);
Vue.use(ConstantsPlugin);
Vue.use(ElementUI, {locale});
Vue.use(vueDebounce);
Vue.use(PermissionsPlugin);


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
