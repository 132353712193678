<template>
  <div>

    <page-heading
      heading="Creds"
    >
      <span slot="help"></span>
      <div slot="right">
        <div class="el-row--flex">
          <el-button
            type="primary"
            size="small"
            icon="fas fa-plus"
            @click="credentialEditModalShow()"
          >
            Добавить
          </el-button>
        </div>
      </div>
    </page-heading>

    <el-table
      :data="credentials"
      border
      style="width: 100%"
      v-loading="loading"
    >
      <el-table-column label="Название" prop="name"></el-table-column>
      <el-table-column label="Логин" prop="login"></el-table-column>
      <el-table-column width="60">
        <template v-slot="scope">
          <el-button
            class="table-action-button"
            type="default"
            round
            size="mini"
            icon="fas fa-pen"
            @click="credentialEditModalShow(scope.row)"
          >
          </el-button>
        </template>
      </el-table-column>
    </el-table>


    <tms-copy-credential-edit-dialog
      ref="credentialEditDialog"
      :copy_id="+this.$route.params.id"
      @credential-saved="loadCopyCredentials"
    ></tms-copy-credential-edit-dialog>

  </div>
</template>

<script>

import TmsCopyEditDialog from "@/components/TmsCopies/TmsCopyEditDialog.vue";
import PageHeading from "@/components/PageHeading.vue";
import TmsCopyCredentialEditDialog from "@/components/TmsCopies/TmsCopyCredentialEditDialog.vue";

export default {
  name: 'TmsCopyCredentials',
  components: {TmsCopyCredentialEditDialog, PageHeading, TmsCopyEditDialog},

  computed: {},
  data() {
    return {
      loading: false,
      credentials: [],
    }
  },
  mounted() {
    this.loadCopyCredentials();
  },
  methods: {
    loadCopyCredentials(){
      this.loading = true;
      this.$api.tmsCopies.getCredentials({
        copy_id: this.$route.params.id,
      })
        .then(data => {
          this.credentials = data.credentials;
        })
        .finally(() => {
          this.loading = false;
        })
    },

    credentialEditModalShow(card) {
      this.$refs.credentialEditDialog.open(card);
    },
  }
}

</script>


<style scoped lang="scss">

</style>
