<template>
  <el-dialog
    title="Редактирование копии"
    :visible.sync="show"
    width="40%"
    top="2vh"
  >
    <el-form
      ref="copyEditForm"
      size="small"
      label-position="top"
      :model="copy"
    >

      <el-form-item label="Название" prop="name">
        <el-input v-model="copy.name"></el-input>
      </el-form-item>
      <el-form-item label="URL страницы логина" prop="name">
        <el-input v-model="copy.login_page_url"></el-input>
      </el-form-item>
      <el-form-item label="Endpoint аутентификации" prop="name">
        <el-input v-model="copy.auth_endpoint"></el-input>
      </el-form-item>
      <el-form-item label="Разрешить вход по супер паролю" prop="name">
        <el-checkbox v-model="copy.allow_super_pass_login"></el-checkbox>
      </el-form-item>
      <div v-show="copy.allow_super_pass_login">
        <el-form-item label="Endpoint получения пользователей" prop="name">
          <el-input v-model="copy.search_users_endpoint"></el-input>
        </el-form-item>
        <el-form-item label="Endpoint логина по супер паролю" prop="name">
          <el-input v-model="copy.login_with_super_pass_endpoint"></el-input>
        </el-form-item>
      </div>

    </el-form>






    <div slot="footer" class="dialog-footer">
      <el-popconfirm
        v-if="copy.id"
        style="float:left"
        confirm-button-text='Да'
        cancel-button-text='нет'
        icon="el-icon-question"
        title="Вы уверены, что хотите удалить копию?"
        @confirm="deleteCopy"
      >
        <el-button
          slot="reference"
          type="danger"
          size="small"
          plain
          icon="el-icon-delete"
          :loading="loading"
        >
          Удалить
        </el-button>
      </el-popconfirm>
      <el-button size="small" @click="close">Отмена</el-button>
      <el-button
        size="small"
        type="primary"
        :loading="loading"
        @click="saveCopy"
      >
        Сохранить
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

export default {
  name: "tms-copy-edit-dialog",
  components: {},

  props: {},
  computed: {},
  watch: {},
  data() {
    return {
      show: false,
      loading: false,

      copy: {
        name: null,
        login_page_url: null,
        auth_endpoint: null,
        allow_super_pass_login: false,
        search_users_endpoint: null,
        login_with_super_pass_endpoint: null,
        super_pass: null,
      },
    }
  },
  mounted() {},
  methods: {
    open(copy) {
      this.copy = Object.assign({}, copy);
      this.show = true;
    },
    close() {
      this.show = false;
    },

    saveCopy() {
        this.loading = true;
        this.$api.tmsCopies.save(this.copy)
          .then(data => {
            this.$notify.success({
              title: 'Сохранено',
              message: 'Копия успешно сохранена'
            });
          })
          .finally(()=>{
            this.loading = false;
            this.close();
            this.$emit('copy-saved');
          })
    },
    deleteCopy() {
        this.loading = true;
        this.$api.tmsCopies.delete({
          id: this.copy.id
        })
          .then(data => {
            this.$notify.success({
              title: 'Сохранено',
              message: 'Копия успешно удалена'
            });
          })
          .finally(()=>{
            this.loading = false;
            this.close();
            this.$emit('copy-saved');
          })
    },

  }
}
</script>

<style scoped lang="scss">

</style>