<template>
  <div>
    <el-row type="flex">
<!--      <notifications-->
<!--        style="margin-left: 15px"-->
<!--        :user-id="user.id"-->
<!--      ></notifications>-->

      <div style="margin-right: 10px; padding-top:9px">
        <div>{{ user.name }}</div>
      </div>

      <el-tooltip content="Выход" placement="top-start">
        <el-button
          size="mini"
          type="text"
          icon="fas fa-sign-out-alt"
          @click="logout"
        >
        </el-button>
      </el-tooltip>


    </el-row>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Notifications from "@/components/Notifications.vue";
import router from "@/router";

export default {
  name: "actions-component",
  components: {Notifications},

  props: {},

  watch: {},
  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {

    }
  },
  mounted() {
  },
  methods: {
    logout() {
      localStorage.removeItem('jwt');
      router.push({ name: 'Login'});
    },
  }
}
</script>


<style lang="scss">

</style>