<template>
  <div class="login" v-loading="loadingLogin">
    <el-card style="padding: 24px">
      <h2>Авторизация</h2>

      <a :href="getGoogleUrl()" class="google-auth">
        <span>Google</span>
      </a>

    </el-card>
  </div>
</template>

<script>


import {mapGetters} from "vuex";

export default {
  name: 'Login',
  components: {},

  computed: {
    ...mapGetters(['loadingLogin']),
  },

  beforeMount() {
    this.code = this.$route.query.code;
    if( this.code ){
      this.checkCode();
    }
  },

  data() {
    return {
      code: null,
    }
  },

  methods: {
    getGoogleUrl() {
      const rootUrl = `https://accounts.google.com/o/oauth2/v2/auth`;

      const options = {
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        redirect_uri: process.env.VUE_APP_GOOGLE_REDIRECT_URI,
        response_type: 'code',
        prompt: 'consent select_account',
        scope: [
          'https://www.googleapis.com/auth/userinfo.profile',
          'https://www.googleapis.com/auth/userinfo.email',
        ].join(' '),
      };

      const qs = new URLSearchParams(options);

      return `${rootUrl}?${qs.toString()}`;
    },

    checkCode(){
      this.$store.dispatch('setLoadingLogin', true);
      this.$api.auth.login( {
        code: this.code,
        redirect_uri: process.env.VUE_APP_GOOGLE_REDIRECT_URI,
      })
        .then(data => {
          localStorage.setItem('jwt', data.token);
          this.$router.push({name: 'Home'});
        })
        .catch(()=>{
          this.$router.push({name: 'Login'});
        })
        .finally(()=>{
          this.$store.dispatch('setLoadingLogin', false);
        })
    },
  }
}

</script>

<style lang="scss" scoped>

.login {
  height: 90vh;
  position: relative;
  /*border: 3px solid green;*/
}

.el-card {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}


</style>
<style lang="scss">

.login .el-card {
  //margin-top:300px;
  width: 340px;
  //display: flex;
  justify-content: center;
}
</style>
