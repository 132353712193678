import axiosInstance from "./axiosInstance";
import authModule from '../auth'
import userModule from '../user'
import tmsCopiesModule from "@/api/tmsCopies";
import rbacModule from "@/api/rbac";

export default {
    auth: authModule(axiosInstance),
    user: userModule(axiosInstance),
    tmsCopies: tmsCopiesModule(axiosInstance),
    rbac: rbacModule(axiosInstance),
}