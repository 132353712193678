
const checkPermissionDataAvailable = (object, permission) => {
    if( !object ) return false;
    if( !object.permissions ) return false;
    if( !object.permissions[permission] ) return false;

    return true;
}


const can = (object, permission) => {
    if( !checkPermissionDataAvailable(object, permission) ) {
        return false;
    }
    return object.permissions[permission].can
}

const rulesPass = (object, permission) => {
    if( !checkPermissionDataAvailable(object, permission) ) {
        return false;
    }
    return object.permissions[permission].rulesPass
}

const canAndRulesPass = (object, permission) => {
    return can(object, permission) && rulesPass(object, permission);
}


const permissions = {
    TMS_COPY: {
        MANAGE: 'tms_copy.manage',
    },
    RBAC: {
        LIST: 'rbac.list',
        SET_PERMISSION: 'rbac.set_permission',
        ROLE_CREATE: 'rbac.role_create',
        ROLE_UPDATE: 'rbac.role_update',
        ROLE_DELETE: 'rbac.role_delete',
        MANAGE_USERS: 'rbac.manage_users',
    },
}

export default {
    install(Vue) {
        Vue.prototype.$permissions = permissions;
        Vue.prototype.$can = can;
        Vue.prototype.$rulesPass = rulesPass;
        Vue.prototype.$canAndRulesPass = canAndRulesPass;
    }
}