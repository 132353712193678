import requestSender from "./base/requestSender";

export default function(axiosInstance){
    return {
        search(payload){
            return requestSender('get', 'tms-copies/search', payload);
        },
        save(payload){
            return requestSender('post', 'tms-copies/save', payload);
        },
        delete(payload){
            return requestSender('post', 'tms-copies/delete', payload);
        },

        getCredentials(payload){
            return requestSender('get', 'tms-copies/get-credentials', payload);
        },
        saveCredential(payload){
            return requestSender('post', 'tms-copies/save-credential', payload);
        },
        deleteCredential(payload){
            return requestSender('post', 'tms-copies/delete-credential', payload);
        },


        getUsers(payload){
            return requestSender('get', 'tms-copies/get-users', payload);
        },
        setUsers(payload){
            return requestSender('post', 'tms-copies/set-users', payload);
        },
        deleteUser(payload){
            return requestSender('post', 'tms-copies/delete-user', payload);
        },

    }
}