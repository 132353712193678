<template>
  <div>

  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {},

  computed: {},
  data() {
    return {

    }
  },
  mounted() {},
  methods: {

  }
}

</script>


<style scoped lang="scss">


</style>
