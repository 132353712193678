<template>
  <div style="display: inline-block" >

    <el-menu
      style="display: inline-block"
      mode="horizontal"
      router
      :default-active="$route.path"
    >

      <el-menu-item
        index="/"
        :route="{name: 'Home'}"
      >
        Главная
      </el-menu-item>
      <el-menu-item
        v-show="$canAndRulesPass(user, $permissions.TMS_COPY.MANAGE)"
        index="/tms-copies"
        :route="{name: 'TmsCopies'}"
      >
        Копии
      </el-menu-item>
      <el-menu-item
        v-show="$canAndRulesPass(user, $permissions.RBAC.LIST)"
        index="/rbac"
        :route="{name: 'Rbac'}"
      >
        RBAC
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "menu-component",
  components: {},

  props: {},

  watch: {},
  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {

    }
  },
  mounted() {
  },
  methods: {

  }
}
</script>


<style lang="scss">

</style>