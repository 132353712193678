<template>
  <el-dialog
    title="Редактирование кредов"
    :visible.sync="show"
    width="40%"
    top="2vh"
  >
    <el-form
      ref="credentialEditForm"
      size="small"
      label-position="top"
      :model="credential"
    >

      <el-form-item label="Название" prop="name">
        <el-input v-model="credential.name"></el-input>
      </el-form-item>
      <el-form-item label="Логин" prop="name">
        <el-input v-model="credential.login"></el-input>
      </el-form-item>
      <el-form-item label="Пароль" prop="name">
        <el-input v-model="credential.password"></el-input>
      </el-form-item>

    </el-form>






    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="close">Отмена</el-button>
      <el-button
        size="small"
        type="primary"
        :loading="loading"
        @click="saveCopy"
      >
        Сохранить
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

export default {
  name: "tms-copy-credential-edit-dialog",
  components: {},

  props: {
    copy_id: {type: Number, required: true},
  },
  computed: {},
  watch: {},
  data() {
    return {
      show: false,
      loading: false,

      credential: {
        name: null,
        login: null,
        password: false,
      },
    }
  },
  mounted() {},
  methods: {
    open(credential) {
      this.credential = Object.assign({}, credential);
      this.credential.copy_id = this.copy_id;
      this.show = true;
    },
    close() {
      this.show = false;
    },

    saveCopy() {
        this.loading = true;
        this.$api.tmsCopies.saveCredential(this.credential)
          .then(data => {
            this.$notify.success({
              title: 'Сохранено',
              message: 'Креды успешно сохранены'
            });
          })
          .finally(()=>{
            this.loading = false;
            this.close();
            this.$emit('credential-saved');
          })
    },

  }
}
</script>

<style scoped lang="scss">

</style>