<template>
  <div>

    <page-heading
      heading="Пользователи, кому разрешен вход по супер паролю"
    >
      <span slot="help"></span>
      <div slot="right">
        <add-users-dialog
          @users-selected="addUsers"
        >
          <el-button
            slot="reference"
            size="mini"
            type="primary"
            icon="fas fa-plus"
          >
            Добавить пользователей
          </el-button>
        </add-users-dialog>
      </div>
    </page-heading>

    <el-table
      :data="users"
      border
      style="width: 100%"
      v-loading="loading"
    >
      <el-table-column label="Name" prop="user.name"></el-table-column>
      <el-table-column label="Email" prop="user.email"></el-table-column>
      <el-table-column width="60">
        <template v-slot="scope">
          <el-button
            class="table-action-button"
            type="default"
            round
            size="mini"
            icon="fas fa-trash"
            @click="deleteUser(scope.row)"
          >
          </el-button>
        </template>
      </el-table-column>
    </el-table>

  </div>
</template>

<script>

import TmsCopyEditDialog from "@/components/TmsCopies/TmsCopyEditDialog.vue";
import PageHeading from "@/components/PageHeading.vue";
import TmsCopyCredentialEditDialog from "@/components/TmsCopies/TmsCopyCredentialEditDialog.vue";
import AddUsersDialog from "@/components/user/AddUsersDialog.vue";

export default {
  name: 'TmsCopyUsers',
  components: {AddUsersDialog, PageHeading},

  computed: {},
  data() {
    return {
      loading: false,
      users: [],
    }
  },
  mounted() {
    this.loadCopyUsers();
  },
  methods: {
    loadCopyUsers(){
      this.loading = true;
      this.$api.tmsCopies.getUsers({
        copy_id: this.$route.params.id,
      })
        .then(data => {
          this.users = data.users;
        })
        .finally(() => {
          this.loading = false;
        })
    },

    addUsers(users){
      let userIds = users.map(u => u.id);
      this.loading = true;
      this.$api.tmsCopies.setUsers({
        copy_id: this.$route.params.id,
        user_ids: userIds,
      })
        .then(data => {
          this.loadCopyUsers();
        })
        .finally(() => {
        })
    },

    deleteUser(user){
      this.loading = true;
      this.$api.tmsCopies.deleteUser({
        copy_id: this.$route.params.id,
        user_id: user.user_id,
      })
        .then(data => {
          this.loadCopyUsers();
        })
        .finally(() => {
        })
    },
  }
}

</script>


<style scoped lang="scss">

</style>
