<template>
  <div v-if="$canAndRulesPass(user, $permissions.TMS_COPY.MANAGE)">

    <page-heading
      heading="Копии TMS"
    >
      <span slot="help"></span>
      <div slot="right">
        <div class="el-row--flex">
          <el-button
            type="primary"
            size="small"
            icon="fas fa-plus"
            @click="copyEditModalShow()"
          >
            Добавить копию
          </el-button>
        </div>
      </div>
    </page-heading>

    <el-table
      :data="copies"
      border
      style="width: 100%"
      v-loading="loading"
    >
      <el-table-column label="Название" prop="name"></el-table-column>
      <el-table-column label="URL страницы логина" prop="login_page_url"></el-table-column>
      <el-table-column width="90">
        <template v-slot="scope">
          <el-button
            class="table-action-button"
            type="default"
            round
            size="mini"
            icon="fas fa-pen"
            @click="copyEditModalShow(scope.row)"
          >
          </el-button>

<!--          <el-tooltip content="Логины" placement="top" :open-delay="500">-->
<!--            <el-button-->
<!--              class="table-action-button"-->
<!--              type="default"-->
<!--              round-->
<!--              size="mini"-->
<!--              icon="fas fa-key"-->
<!--              @click="$router.push({ name: 'TmsCopyCredentials', params: { id: scope.row.id } })"-->
<!--            >-->
<!--            </el-button>-->
<!--          </el-tooltip>-->

          <el-tooltip content="Пользователи Knomary, для которых доступен вход без пароля" placement="top" :open-delay="500">
            <el-button
              class="table-action-button"
              type="default"
              round
              size="mini"
              icon="fas fa-user"
              @click="$router.push({ name: 'TmsCopyUsers', params: { id: scope.row.id } })"
            >
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>


    <tms-copy-edit-dialog
      ref="copyEditDialog"
      @copy-saved="loadCopies"
    ></tms-copy-edit-dialog>

  </div>
</template>

<script>

import TmsCopyEditDialog from "@/components/TmsCopies/TmsCopyEditDialog.vue";
import PageHeading from "@/components/PageHeading.vue";
import TmsCopyUsers from "@/views/TmsCopyUsers.vue";
import {mapGetters} from "vuex";

export default {
  name: 'TmsCopies',
  components: {PageHeading, TmsCopyEditDialog},

  computed: {
    ...mapGetters([ 'user' ]),
  },
  data() {
    return {
      loading: false,
      copies: [],
    }
  },
  mounted() {
    this.loadCopies();
  },
  methods: {
    loadCopies(){
      this.loading = true;
      this.$api.tmsCopies.search()
        .then(data => {
          this.copies = data.copies;
        })
        .finally(() => {
          this.loading = false;
        })
    },

    copyEditModalShow(card) {
      this.$refs.copyEditDialog.open(card);
    },
  }
}

</script>


<style scoped lang="scss">

</style>
