<template>
  <el-container id="app">
    <el-header style="height: 41px; border-bottom: solid 1px #e6e6e6;">
      <el-row type="flex" justify="space-between">
        <div>
          <span style="margin-right:10px">
            <router-link :to="{name: 'Home'}">
              <img alt="Главная" style="padding-bottom: 11px" height="25" src="@/assets/img/logo.png"/>
            </router-link>
          </span>


          <menu-component></menu-component>

        </div>
        <div>

          <actions-component></actions-component>

        </div>
      </el-row>
    </el-header>
    <el-main>
      <router-view />
    </el-main>

  </el-container>

</template>

<script>
import {mapGetters} from 'vuex';
import MenuComponent from "@/components/header/MenuComponent.vue";
import ActionsComponent from "@/components/header/ActionsComponent.vue";

export default {
  name: "default-layout",
  components: {ActionsComponent, MenuComponent},

  computed: {
    ...mapGetters(['user']),
    layout() {
      return this.$route.meta.layout || 'default-layout'
    }
  },

  data() {
    return {

    }
  },

  created() {
    this.$store.dispatch('fetchUser');
  },

  mounted() {},
  methods: {}

}
</script>

<style>

.el-avatar > img {
  display: unset;
  height: unset;
  width: 100%;
}

</style>